import { IndustryService } from 'models/IndustryService';
import { PostlikeWithAcfSeo, PostlikeWithFeaturedImage } from 'src/types';
import IndustryQuery_Industry from './IndustryQuery_Industry';
import ServiceQuery_Service from './ServiceQuery_Service';

type RequiredInput = PostlikeWithFeaturedImage & PostlikeWithAcfSeo & {
  [key:string]: any,
  acf?: {
    linkedIndustry?: ReadonlyArray<PostlikeWithAcfSeo | undefined> | null,
    linkedService?: ReadonlyArray<PostlikeWithAcfSeo | undefined> | null,
  }
};

export default (
  queryData?: RequiredInput,
): IndustryService | null => {
  if (!queryData) return null;
  const linkedService =
    (queryData.acf?.linkedService?.length && queryData.acf.linkedService[0]) || null;
  const linkedIndustry =
    (queryData.acf?.linkedIndustry?.length && queryData.acf.linkedIndustry[0]) || null;
  return new IndustryService({
    name: queryData.title || '',
    slug: queryData.slug || '',
    metaDescription: queryData.acfSeo?.metaDescription || '',
    navTitle: queryData.acfSeo?.navTitle || '',
    metaTitle: queryData.acfSeo?.metaTitle || '',
    image: queryData.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    contentHTML: queryData.content,
    excerptHTML: queryData.excerpt,
    linkedService: ServiceQuery_Service(linkedService),
    linkedIndustry: IndustryQuery_Industry(linkedIndustry),
  });
};
