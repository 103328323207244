import { useStaticQuery, graphql } from 'gatsby';
import { Client } from 'src/models/Client';
import transformer from 'src/transformers/ClientQuery_Client';
import isNotNull from 'types/guards/isNotNull';

const useConnector = (): Client[] => {
  const queryData = useStaticQuery<GatsbyTypes.ClientsForClientsBlockQuery>(graphql`
    query ClientsForClientsBlock {
      allWpClient {
        nodes {
          title
          slug
          content
          acf {
            url {
              url
            }
            # relevantIndustries todo
            # relevantServices todo
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 150)
                }
              }
            }
          }
        }
      }
    }
  `);
  const normalizedData = queryData.allWpClient.nodes
    .map((item) => transformer(item))
    .filter(isNotNull);
  return normalizedData;
};

export default useConnector;
