import React from 'react';
import { graphql } from 'gatsby';
import Container from 'components/Container';
import Block from 'components/Block';
import PageHeader from 'components/PageHeader';
import faqTransformer from 'src/transformers/FaqQuery_Faq';
import FaqAccordion from 'components/accordions/FaqAccordion';
import GenericGridBlock from 'components/GenericGridBlock';
import GeneralTile from 'src/components/tiles/GeneralTile';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import CtaBlock from 'components/CtaBlock';
import IndustriesBlock from 'src/components/IndustriesBlock';
import FeatureCopyBlock from 'src/components/FeatureCopyBlock';
import CtaButton from 'src/components/CtaButton';
import Spacer from 'src/components/Spacer';
import { resolveUrl } from 'src/helpers';
import CaseStudyQuery_CaseStudy from 'src/transformers/CaseStudyQuery_CaseStudy';
import PostQuery_Post from 'src/transformers/PostQuery_Post';
import ClientQuery_Client from 'src/transformers/ClientQuery_Client';
import isNotNull from 'src/types/guards/isNotNull';
import ClientsBlock from 'src/components/ClientsBlock';
import TestimonialQuery_Testimonial from 'src/transformers/TestimonialQuery_Testimonial';
import TestimonialBlock from 'src/components/TestimonialBlock';
import Seo from 'src/lib/components/Seo';
import SubNavMenu, { Props as SubNavMenuProps } from 'src/components/SubNavMenu';
import IndustryServiceQuery_IndustryService from 'src/transformers/IndustryServiceQuery_IndustryService';
import ContentParser from 'src/components/ContentParser';
import IndustryQuery_Industry from 'src/transformers/IndustryQuery_Industry';

interface Props {
  data: GatsbyTypes.IndustrySingleAssociatedPostsAndIndustryServicesQuery,
  // pageContext: { industryId: string },
}

export const query = graphql`
  query IndustrySingleAssociatedPostsAndIndustryServices(
    $industryId: String,
    $slug: String,
    $associatedIndustryServiceIds: [String],
  ) {
    wpIndustry(id: { eq: $industryId }) {
      ...IndustryWithIcon
      acf {
        copyBlock1
        linkedFaqs {
          ... on WpFaq {
            ...FaqFragment
          }
        }
        linkedCaseStudies {
          ... on WpCaseStudy {
            ...CaseStudyWithThumbnail
          }
        }
        linkedClients {
          ... on WpClient {
            ...ClientWithThumbnail
          }
        }
        linkedTestimonial {
          ... on WpTestimonial {
            ...TestimonialWithThumbnail
          }
        }
      }
    }
    relatedPosts: allWpPost(
      filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
      sort: {fields: date, order: DESC}
      limit: 3
    ) {
      nodes {
        ...PostWithThumbnail
      }
    }
    relatedIndustryServices: allWpIndustryService(
      filter: {id: {in: $associatedIndustryServiceIds}},
      sort: {order: ASC, fields: menuOrder}
    ) {
      nodes {
        ...IndustryServiceWithLinkedParents
      }
    }
  }
`;


const IndustryPage: React.FC<Props> = ({ data }) => {
  const industryQuery = data.wpIndustry;
  const industry = IndustryQuery_Industry(industryQuery);
  if (!industry) return null;

  const relatedPostsRaw = data.relatedPosts.nodes;

  const relatedIndustryServices = Array.from(
    data.relatedIndustryServices.nodes,
    IndustryServiceQuery_IndustryService,
  ).filter(isNotNull);

  const relatedPosts = Array.from(relatedPostsRaw, PostQuery_Post).filter(isNotNull);

  const faqLists = industryQuery?.acf?.linkedFaqs;
  const firstFaqList = faqLists && faqLists?.length > 0 ? faqLists[0] : undefined;
  const faqs = faqTransformer(firstFaqList);

  const testimonialsArray = industryQuery?.acf?.linkedTestimonial || [];
  const [testimonialRaw] = testimonialsArray;
  const testimonial = TestimonialQuery_Testimonial(testimonialRaw);

  const caseStudiesRaw = industryQuery?.acf?.linkedCaseStudies || [];
  const caseStudies = Array.from(caseStudiesRaw, CaseStudyQuery_CaseStudy).filter(isNotNull);

  const clientsRaw = industryQuery?.acf?.linkedClients || [];
  const clients = Array.from(clientsRaw, ClientQuery_Client).filter(isNotNull);

  const breadcrumbs: BreadcrumbsProps['items'] = [
    { label: 'Industries', url: resolveUrl.industry('') },
    { label: industry.navTitle },
  ];

  const subNavMenuItems: SubNavMenuProps['items'] = (
    relatedIndustryServices.map((item) => ({
      label: item.getTitle(),
      url: resolveUrl.industryService(
        item.linkedIndustry?.slug || '',
        item.linkedService?.slug || '',
      ),
    }))
  );

  return (
    <>
      <Seo
        title={industry.metaTitle}
        // titlePath="Industries"
        description={industry.metaDescription}
      />
      <PageHeader
        category="Industries"
        h1={industry.h1}
        backgroundText={industry.contentHtml}
      />
      {subNavMenuItems.length > 0 && (
        <Block darkBg type="noPadding">
          <SubNavMenu items={subNavMenuItems} />
        </Block>
      )}
      <Container readableWidth>
        <Breadcrumbs items={breadcrumbs} />
        {industry.contentHtml && (
          <Block>
            <ContentParser>{industry.contentHtml}</ContentParser>
            <Spacer mb={4} />
            <CtaButton type="primary" />
          </Block>
        )}
      </Container>

      {caseStudies?.length > 0 && (
        <Block>
          <Container yPadding={0}>
            <GenericGridBlock title={`${industry.navTitle} case studies`} smallTitle>
              {caseStudies.slice(0, 3).map((caseStudy) => (
                <GeneralTile
                  key={caseStudy.title}
                  title={caseStudy.title}
                  image={caseStudy.image}
                  href={resolveUrl.caseStudy(caseStudy.slug)}
                />
              ))}
            </GenericGridBlock>
          </Container>
        </Block>
      )}

      {testimonial && (
        <Block darkBg type="noPadding">
          <Container>
            <TestimonialBlock
              humanName={testimonial.name}
              humanTitle={testimonial.jobTitle}
              quoteHTML={testimonial.quoteCopyHTML}
            />
          </Container>
        </Block>
      )}

      {industryQuery?.acf?.copyBlock1 && (
        <Block>
          <Container>
            <FeatureCopyBlock copyHTML={industryQuery?.acf?.copyBlock1} />
          </Container>
        </Block>
      )}

      {clients.length > 0 && (
        <Block darkBg type="noPadding">
          <Container>
            <ClientsBlock tilesOverride={clients} />
          </Container>
        </Block>
      )}

      {faqs && faqs.length > 0 && (
        <Block contain readableWidth>
          <FaqAccordion faqs={faqs} />
        </Block>
      )}

      {relatedPosts?.length > 0 && (
        <Block>
          <Container yPadding={0}>
            <GenericGridBlock title={`Latest ${industry.navTitle} insight`} smallTitle>
              {relatedPosts.slice(0, 3).map((post) => (
                <GeneralTile
                  key={post.title}
                  title={post.title}
                  image={post.featuredImage}
                  href={resolveUrl.post(post.slug)}
                />
              ))}
            </GenericGridBlock>
          </Container>
        </Block>
      )}

      <Block darkBg>
        <Container yPadding={0}>
          <IndustriesBlock />
        </Container>
      </Block>

      <CtaBlock />
    </>
  );
};

export default IndustryPage;
