/* eslint-disable @typescript-eslint/lines-between-class-members */
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { conditionalItem } from 'src/helpers';
import { Industry } from './Industry';
import { Service } from './Service';
import { SitePage, InstanceProps as SitePageInstanceProps } from './SitePage';

interface ConstructorProps {
  name: string,
  slug: string,
  metaDescription: string,
  navTitle?: string,
  metaTitle?: string,
  image?: IGatsbyImageData,
  contentHTML?: string,
  excerptHTML?: string,
  linkedService?: Service | null,
  linkedIndustry?: Industry | null,
}

type InstanceProps = SitePageInstanceProps & {
  name: string,
  image?: IGatsbyImageData,
  contentHTML?: string,
  excerptHTML?: string,
  linkedService?: Service | null,
  linkedIndustry?: Industry | null,
  getTitle: () => string,
};


export class IndustryService extends SitePage implements InstanceProps {
  name: string;
  image?: IGatsbyImageData;
  contentHTML?: string;
  excerptHTML?: string;
  linkedService?: Service | null;
  linkedIndustry?: Industry | null;

  constructor(props: ConstructorProps) {
    super({
      contentHtml: props.contentHTML || '',
      metaDescription: props.metaDescription,
      title: props.name,
      slug: props.slug,
      navTitle: props.navTitle,
      metaTitle: props.metaTitle,
      featuredImage: props.image,
    });

    this.name = props.name;
    this.image = props.image;
    this.contentHTML = props.contentHTML;
    this.excerptHTML = props.excerptHTML;
    this.linkedService = props.linkedService;
    this.linkedIndustry = props.linkedIndustry;
  }

  getTitle() {
    return this._navTitle || (
      [
        ...conditionalItem(this.linkedIndustry?.navTitle),
        ...conditionalItem(this.linkedService?.navTitle),
      ].join(' ')
    );
  }
}
