import React from 'react';
import SmallTileBlock from 'components/SmallTileBlock';
import { Props as SmallTileProps } from 'components/SmallTileBlock/SmallTile';
import hasGatsbyImageData from 'types/guards/hasGatsbyImageData';
import { Client } from 'src/models/Client';
import useConnector from './useConnector';

export interface Props {
  title?: string,
  tilesOverride?: Client[],
}

const ClientsBlock:React.FC<Props> = ({ title = 'Clients.', tilesOverride }) => {
  const defaultTiles = useConnector();
  const data = tilesOverride || defaultTiles;
  const tiles: SmallTileProps[] = data.reduce<SmallTileProps[]>((acc, item) => ([
    ...acc,
    ...(hasGatsbyImageData(item) ? [{
      _id: item._id,
      title: item.name,
      image: item.image,
    }] : []),
  ]), []);

  return <SmallTileBlock title={title} items={tiles} tilePadding={2} />;
};
export default ClientsBlock;
